<template>
  <v-card class="rounded-lg">
    <v-card-title>
      Form {{ form.form_status == 'add' ? 'Tambah' : 'Ubah' }} Lembaga
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text class="mt-4">
      <ValidationObserver ref="observer">
        <v-form>
          <v-row no-gutters class="mb-5">
            <v-col cols="6" sm="6">
              <p class="mb-1">Name</p>
              <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="form.name"
                  filled
                  required
                  outlined
                  :error-messages="errors"
                  hide-details="auto">
                </v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-5">
            <v-col cols="6" sm="6">
              <p class="mb-1">Email</p>
              <!-- <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }"> -->
                <v-text-field
                  v-model="form.email"
                  filled
                  required
                  outlined
                  hide-details="auto">
                  <!-- :error-messages="errors" -->
                </v-text-field>
              <!-- </ValidationProvider> -->
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-5">
            <v-col cols="6" sm="6">
              <p class="mb-1">Chairman</p>
              <!-- <ValidationProvider name="Chairman" rules="required" v-slot="{ errors }"> -->
                <v-text-field
                  v-model="form.chairman"
                  filled
                  required
                  outlined
                  hide-details="auto">
                  <!-- :error-messages="errors" -->
                </v-text-field>
              <!-- </ValidationProvider> -->
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-5" v-show="form.form_status == 'add'">
            <v-col cols="6" sm="6">
              <p class="mb-1">Phone number</p>
              <!-- <ValidationProvider name="Phon" rules="required" v-slot="{ errors }"> -->
              <v-text-field
                v-model="form.phone"
                filled
                required
                outlined
                hide-details="auto">
                <!-- :error-messages="errors" -->
              </v-text-field>
              <!-- </ValidationProvider> -->
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-5">
            <v-col cols="6" sm="6">
              <p class="mb-1">Address</p>
              <!-- <ValidationProvider name="Address" rules="required" v-slot="{ errors }"> -->
              <v-text-field
                v-model="form.address"
                filled
                required
                outlined
                hide-details="auto">
                <!-- :error-messages="errors" -->
              </v-text-field>
              <!-- </ValidationProvider> -->
            </v-col>
          </v-row>

          <!-- FITUR TEST -->
          <v-divider></v-divider>
          <div
            style="
              margin-top: 30px;
              margin-bottom: 20px;
              font-size: 18px;
              font-weight: 600;">
            Pengaturan Perangkat  untuk Peserta
          </div>
          <v-row no-gutters>
            <v-col cols="6" sm="6">
              <div class="d-flex align-center">
                <div 
                  style="
                    margin-right: 10px;
                    font-weight: 500;
                    font-size: 16px;">
                  Kamera
                </div>
                <div
                  v-if="form.active_camera"
                  style="
                    color: #F05326;
                    font-size: 12px;">
                  Mode Aktif
                </div>
                <div
                  v-else
                  style="
                    color: #8492A6;
                    font-size: 12px;">
                  Non aktif
                </div>
              </div>
              <div>
                Pengaturan kamera device 
              </div>
            </v-col>
            <v-col cols="1" sm="1">
              <v-switch
                v-model="form.active_camera"
                @change="changeActiveCamera()"
                inset>
              </v-switch>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" sm="6">
              <div class="d-flex align-center">
                <div 
                  style="
                    margin-right: 10px;
                    font-weight: 500;
                    font-size: 16px;">
                  Open Tab
                </div>
                <div
                  v-if="form.setting.identification.new_tab"
                  style="
                    color: #F05326;
                    font-size: 12px;">
                  Mode Aktif
                </div>
                <div
                  v-else
                  style="
                    color: #8492A6;
                    font-size: 12px;">
                  Non aktif
                </div>
              </div>
              <div>
                Pengaturan indentifikasi open tab peserta
              </div>
            </v-col>
            <v-col cols="1" sm="1">
              <v-switch
                @change="changeActiveTab()"
                v-model="form.setting.identification.new_tab"
                inset>
              </v-switch>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" sm="6">
              <div class="d-flex align-center">
                <div 
                  style="
                    margin-right: 10px;
                    font-weight: 500;
                    font-size: 16px;">
                  Deteksi wajah
                </div>
                <div
                  v-if="form.setting.identification.face"
                  style="
                    color: #F05326;
                    font-size: 12px;">
                  Mode Aktif
                </div>
                <div
                  v-else
                  style="
                    color: #8492A6;
                    font-size: 12px;">
                  Non aktif
                </div>
              </div>
              <div>
                Pengaturan identifikasi wajah peserta test
              </div>
            </v-col>
            <v-col cols="1" sm="1">
              <v-switch
                @change="changeActiveFace()"
                v-model="form.setting.identification.face"
                :disabled="form.disabled || !form.active_camera"
                inset>
              </v-switch>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" sm="6">
              <div class="d-flex align-center">
                <div 
                  style="
                    margin-right: 10px;
                    font-weight: 500;
                    font-size: 16px;">
                  Suara
                </div>
                <div
                  v-if="form.setting.identification.voice"
                  style="
                    color: #F05326;
                    font-size: 12px;">
                  Mode Aktif
                </div>
                <div
                  v-else
                  style="
                    color: #8492A6;
                    font-size: 12px;">
                  Non aktif
                </div>
              </div>
              <div>
                Pengaturan Identifikasi suara percakapan
              </div>
            </v-col>
            <v-col cols="1" sm="1">
              <v-switch
                @change="changeActiveVoice()"
                v-model="form.setting.identification.voice"
                inset>
              </v-switch>
            </v-col>
          </v-row>

          <!-- FITUR GUGUR TEST -->
          <v-divider></v-divider>
          <div
            style="
              margin-top: 30px;
              margin-bottom: 20px;
              font-size: 18px;
              font-weight: 600;">
            Pengaturan Sistem Gugur untuk Peserta
          </div>
          <v-row no-gutters>
            <v-col cols="6" sm="6">
              <div class="d-flex align-center">
                <div 
                  style="
                    margin-right: 10px;
                    font-weight: 500;
                    font-size: 16px;">
                  Sistem gugur open tab 
                </div>
                <div
                  v-if="form.setting.failed.new_tab"
                  style="
                    color: #F05326;
                    font-size: 12px;">
                  Mode Aktif
                </div>
                <div
                  v-else
                  style="
                    color: #8492A6;
                    font-size: 12px;">
                  Non aktif
                </div>
              </div>
              <div>
                Pengaturan sistem gugur open tab untuk peserta
              </div>
            </v-col>
            <v-col cols="1" sm="1">
              <v-switch
                :disabled="!form.setting.identification.new_tab"
                v-model="form.setting.failed.new_tab"
                inset>
              </v-switch>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" sm="6">
              <div class="d-flex align-center">
                <div 
                  style="
                    margin-right: 10px;
                    font-weight: 500;
                    font-size: 16px;">
                  Sistem gugur deteksi wajah 
                </div>
                <div
                  v-if="form.setting.failed.face"
                  style="
                    color: #F05326;
                    font-size: 12px;">
                  Mode Aktif
                </div>
                <div
                  v-else
                  style="
                    color: #8492A6;
                    font-size: 12px;">
                  Non aktif
                </div>
              </div>
              <div>
                Pengaturan sistem gugur deteksi wajah peserta
              </div>
            </v-col>
            <v-col cols="1" sm="1">
              <v-switch
                v-model="form.setting.failed.face"
                :disabled="!form.setting.identification.face"
                inset>
              </v-switch>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" sm="6">
              <div class="d-flex align-center">
                <div 
                  style="
                    margin-right: 10px;
                    font-weight: 500;
                    font-size: 16px;">
                  Sistem Gugur Suara
                </div>
                <div
                  v-if="form.setting.failed.voice"
                  style="
                    color: #F05326;
                    font-size: 12px;">
                  Mode Aktif
                </div>
                <div
                  v-else
                  style="
                    color: #8492A6;
                    font-size: 12px;">
                  Non aktif
                </div>
              </div>
              <div>
                Pengaturan sistem gugur suara peserta
              </div>
            </v-col>
            <v-col cols="1" sm="1">
              <v-switch
                :disabled="!form.setting.identification.voice"
                v-model="form.setting.failed.voice"
                inset>
              </v-switch>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-card-text>
    <v-card-actions>
      <v-btn
        width="100"
        elevation="0"
        class="text-capitalize mr-2"
        :loading="process.run"
        :disabled="process.run"
        to="/institution">
        Cancel
      </v-btn>
      <v-btn 
        width="100"
        elevation="0"
        color="#F05326"
        class="text-capitalize white--text"
        :loading="process.run"
        :disabled="process.run"
        @click="saveInstitution()">
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      detail: {
        institusion: { 
          license: ""
        }
      },
      detailInstitution: {},
      show_password: false,
      form: {
        filter_place: "",
        disabled: false,
        // #Field Add
        id: "",
        form_status: 'add',
        name: "",
        chairman: "",
        phone: "",
        email: "",
        address: "",
        active_camera: true,
        setting : {
          identification : {
            face : true,
            voice : true,
            new_tab : true
          },
          failed : {
            face : true,
            voice : true,
            new_tab : true
          }
        }
      },
      dialog: {
        confirmation: false,
      },
      process: {
        run: false,
      },
      notification: {
        state: false,
        text: "",
        color: ""
      },
    }
  },
  created() {
    if (this.$route.name.includes("form-lembaga")) {
      let title = "" 
      if (this.$route.query.id) {
        title = "Form Ubah Lembaga"
      }else{
        title = "Form Tambah Lembaga"
      }
      this.$emit("page-changed", 1, { title, link: "#" });
    }
  },
  mounted(){
    if (this.$route.query.id) {
      this.initialize()      
    }
  },
  methods: {
    changeActiveCamera() {
      if (!this.form.active_camera) {
        this.form.setting.identification.face = false;
        this.form.setting.failed.face = false;
        this.form.disabled = true;
      }else{
        this.form.disabled = false;
      }
    },
    changeActiveTab() {
      if (!this.form.setting.identification.new_tab) {
        this.form.setting.failed.new_tab = false;
      }
    },
    changeActiveFace() {
      if (!this.form.setting.identification.face) {
        this.form.setting.failed.face = false;
      }
    },
    changeActiveVoice() {
      if (!this.form.setting.identification.voice) {
        this.form.setting.failed.voice = false;
      }
    },
    initialize() {
      this.axios.get(`/institusion/v1/get/institusion/detail/${this.$route.query.id}`)
        .then((res) => res.data)
        .then((res) => {
          if (res.status === 200) {
            this.form = {
              id: res.data.id,
              form_status: 'update',
              name: res.data.name,
              chairman: res.data.chairman,
              phone: res.data.phone,
              email: res.data.email,
              address: res.data.address,
              active_camera: res.data.active_camera,
              setting : {
                identification : {
                  face : res.data.setting.identification.face,
                  voice : res.data.setting.identification.voice,
                  new_tab : res.data.setting.identification.new_tab,
                },
                failed : {
                  face : res.data.setting.failed.face,
                  voice : res.data.setting.failed.voice,
                  new_tab : res.data.setting.failed.new_tab,
                }
              }
            }
          }
        }).catch((error) => {
          console.log(error);
          this.process.run = false;
        });
    },
    async saveInstitution(){
      this.process.run = true;
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        let data = {
          name: this.form.name,
          chairman: this.form.chairman,
          phone: this.form.phone,
          email: this.form.email,
          address: this.form.address,
          active_camera: this.form.active_camera,
          setting : {
            identification : {
              face : this.form.setting.identification.face,
              voice : this.form.setting.identification.voice,
              new_tab : this.form.setting.identification.new_tab,
            },
            failed : {
              face : this.form.setting.failed.face,
              voice : this.form.setting.failed.voice,
              new_tab : this.form.setting.failed.new_tab,
            }
          }
        }
        if (this.form.form_status == 'add') {
          await this.axios.post(`/institusion/v1/create/institusion`, data).then((response) => {
            let res = response.data
            if (res.status == 201) {
              this.process.run = false
              this.dialog.form = false
              this.notification.state = true;
              this.notification.color = "#4CAF50";
              this.notification.text = "Lembaga berhasil di tambahkan";
              this.$router.push(`/institution/member/${res.data.id}`)
            }else {
              this.process.run = false
              this.dialog.form = false
            }
          }).catch(error => {
            let message = error.message;
            if (error.response) {
              message = error.response.data.message;
            }
  
            this.notification.state = true;
            this.notification.color = "error";
            this.notification.text = "Error: " + message;
            console.error(error);
            this.process.run = false
          })
        }else{
          await this.axios.put(`/institusion/v1/update/institusion/${this.form.id}`, data).then((response) => {
            let res = response.data
            if (res.status == 200) {
              this.process.run = false
              this.dialog.form = false
              this.notification.state = true;
              this.notification.color = "#4CAF50";
              this.notification.text = "Lembaga berhasil di ubah";
              this.$router.push(`/institution/member/${res.data.id}`)
            }else {
              this.process.run = false
              this.dialog.form = false
            }
          }).catch(error => {
            let message = error.message;
            if (error.response) {
              message = error.response.data.message;
            }
  
            this.notification.state = true;
            this.notification.color = "error";
            this.notification.text = "Error: " + message;
            console.error(error);
            this.process.run = false
          })
        }
        this.initialize()
      }else{
        this.process.run = false
      }
    },
  }
}
</script>

<style>

</style>