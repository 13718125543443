import Vue from 'vue'
import VueRouter from 'vue-router'
import Views from '../views'
// import Home from '../views/Home'
// import Unauth from '../views/unauth'
import store from '../store'

Vue.use(VueRouter)

// const QuestionBank = [
// ]

let routes = {
  auth: [
    { path: '/dashboard', name: 'dashboard', component: Views.Dashboard },

    // Class
    // { path: '/class', name: 'class-online' },
    // { path: '/class/create', name: 'class-create' },

    // Topik & Aktivitas
    // { path: '/class/:class', name: 'class-detail' },
    // { path: '/class/:class/topic', name: 'topic-activity' },
    // { path: '/class/:class/topic/:topic/create-activity', name: 'activity-create' },

    // EPOT
    // { path: '/epot', name: 'epot' },
    // { path: '/epot/create', name: 'epot-create' },
    // { path: '/epot/:paket', name: 'epot-detail'},
    { path: '/update-password', name: 'update-password', component: Views.Auth.UpdatePassword },
    { path: '/exam/greeting', name: 'exam-greeting', component: Views.Epot.EpotGreeting },
    { path: '/exam', name: 'exam-list', component: Views.Epot.EpotList },
    {
      path: '/exam/create', component: Views.Epot.EpotForm, children: [
        { path: '/', name: 'exam-create' },
        { path: 'import-question', name: 'exam-create-import-questions', component: Views.Bank.ImportQuestions },
        { path: 'import-group', name: 'exam-create-import-question-groups', component: Views.Bank.ImportQuestionGroups }
      ]
    },
    {
      path: '/exam/:id', component: Views.Epot.EpotForm, children: [
        { path: '/', name: 'exam-detail' },
        { path: 'import-question', name: 'exam-detail-import-questions', component: Views.Bank.ImportQuestions },
        { path: 'import-group', name: 'exam-detail-import-question-groups', component: Views.Bank.ImportQuestionGroups }
      ]
    },

    // { path: '/epot/demo-question', name: 'demo-question', component: Views.DemoQuestionBank },
    {
      path: '/epot/createx', component: Views.Epot.EpotCreatex, children: [
        { path: '/', name: 'epot-createx', },
        { path: 'import-question', name: 'import-questionsx', component: Views.Bank.ImportQuestions },
        { path: 'import-group', name: 'import-question-groupsx', component: Views.Bank.ImportQuestionGroups }
      ]
    },

    // Sertifikat
    { path: '/sertifikat', name: 'certificate', component: Views.Certificate.CertificateList },
    { path: '/sertifikat-b2b', name: 'certificate-b2b', component: Views.Certificate.CertificateListB2B },
    { path: '/sertifikat-fisik', name: 'certificate-fisik', component: Views.Certificate.CertificateListFisik },
    { path: '/sertifikat-send', name: 'certificate-send', component: Views.Certificate.CertificateSendList },
    { path: '/lisensi', name: 'lisensi', component: Views.Lisence.Search },
    { path: '/lisensi-order', name: 'lisensi-order', component: Views.Lisence_order.Order },
    { path: '/order', name: 'order', component: Views.Order.Index },

    // Member
    { path: '/member', name: 'member', component: Views.Member.MemberList },
    { path: '/member-point', name: 'data point', component: Views.Member.DataPointList },

    // Capture Image User Test
    { path: '/galery-capture', name: 'capture-list', component: Views.Capture_user.CaptureList},
    { path: '/galery-capture/detail/:id/:lisenci', name: 'capture-detail', component: Views.Capture_user.CaptureDetail},

    // Institution
    { path: '/institution', name: 'lembaga-list', component: Views.Institution.InstitutionList},
    { path: '/institution/form', name: 'form-lembaga', component: Views.Institution.InstitutionForm},
    { path: '/institution/member/:id', name: 'member-lembaga', component: Views.Institution.MemberInstitution },

    // Import Student
    { path: '/import-user', name: 'import-user', component: Views.Import.FormImportStudent},

    // Maintenance
    { path: '/maintenance', name: 'maintenance-list', component: Views.Maintenance.FormMaintenanceService},
  ],
  guest: [
    { path: '/', name: 'login', component: Views.Auth.Login },
    { path: '/forgot-password', name: 'forgot-password', component: Views.Auth.ForgotPassword },
    { path: '/password-reset', name: 'password-reset', component: Views.Auth.PasswordReset },
    { path: '/password-changed', name: 'password-changed', component: Views.Auth.PasswordChanged },
  ],
  default: [
    { path: '*', name: '404 Not Found', component: Views.Errors.NotFound },
  ]
}

routes.auth.forEach(route => { route.meta = { auth: true } });
routes.guest.forEach(route => { route.meta = { guest: true } });
routes = [
  ...routes.auth,
  ...routes.guest,
  ...routes.default,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/' && store.getters.isLoggedIn) {
    return next('/dashboard')
  }
  if (to.meta.auth && !store.getters.isLoggedIn) {
    sessionStorage.setItem('ytc_redirect', location.pathname)
    return next('/')
  }
  if (to.meta.guest && store.getters.isLoggedIn) {
    return next('/dashboard')
  }
  return next()
})

export default router
