<template>
  <v-card class="shadow-0" elevation="0" style="background: transparent">
    <!-- NOTIFICATION -->
    <v-snackbar :color="color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filtered" scrollable persistent max-width="600px">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 text-first d-flex mb-4" style="background-color: #F05326;height:50px">
          <span class="white--text">
            Dialog Filter
          </span>
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.filtered = false" color="white">ri-close-line</v-icon>
        </v-card-title>
        <v-card-text style="height: 350px;" class="py-5">
          <v-row>
            <v-col>
              <div>Tanggal Mulai Tes</div>
              <v-menu
                v-model="start_test_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter_start_test"
                    label="Tanggal Mulai Test"
                    outlined
                    dense
                    class="mt-3"
                    readonly
                    v-bind="attrs"
                    v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="form.filter_start_test"
                  @input="start_test_menu = false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <div>Tanggal Selesai Test</div>
              <v-menu
                v-model="end_test_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter_end_test"
                    label="Tanggal Selesai Test"
                    outlined
                    dense
                    class="mt-3"
                    readonly
                    v-bind="attrs"
                    v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="form.filter_end_test"
                  @input="end_test_menu = false">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <div class="mt-3">
                Asal Daerah
              </div>
            </v-col>
            <v-col cols="8">
              <v-text-field
                dense
                outlined
                color="#F05326"
                label="Filter Asal Daerah"
                v-model="form.filter_place">
              </v-text-field>
              <!-- <v-select
                dense
                outlined
                color="#F05326"
                item-text="text"
                item-value="text"
                label="Pilih Nama Kota"
                v-model="form.filter_place"
                :items="place">
              </v-select> -->
            </v-col>
          </v-row>

          <!-- Imput -->
          <!-- <v-row>
            <v-col cols="12">
              <div>
                Range Umur
              </div>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                label="Dari Umur"
                color="#F05326"
                v-model="form.filter_age_min">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                label="Sampai Umur"
                color="#F05326"
                v-model="form.filter_age_max">
              </v-text-field>
            </v-col>
          </v-row> -->

          <!-- Slider -->
          <v-row>
            <div class="pl-3">
              Range Umur
            </div>
            <v-col cols="12" class="py-0">
              <v-subheader class="pl-0 pb-6 font-weight-bold">
                Dari Umur
              </v-subheader>
              <v-slider
                step="1"
                value=""
                :thumb-size="24"
                @change="changeFrom"
                thumb-label="always">
              </v-slider>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-subheader class="pl-0 pb-6 font-weight-bold">
                Sampai Umur
              </v-subheader>
              <v-slider
                step="1"
                :thumb-size="24"
                @change="changeTo"
                thumb-label="always">
              </v-slider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            width="100"
            color="#F05326"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="initialize()"
          >
            Filter
          </v-btn>
          <v-btn
            width="100"
            elevation="0"
            color="yellow darken-2"
            class="text-capitalize white--text"
            :disabled="process.run"
            @click="resetFilter()"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="end">
      <v-col cols="5">
        <div class="d-flex text-right">
          <v-text-field
            v-model="searching"
            class="mr-3"
            outlined
            autocomplete="off"
            dense
            v-on:keyup.enter="initialize(searching,'')"
            prepend-inner-icon="ri-search-line"
            placeholder="Cari Nama / Email . . ."
            hide-details="auto">
            <template v-slot:append v-if="searching">
              <v-icon @click="searching = '';initialize()">ri-close-line</v-icon>
            </template>
          </v-text-field>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="dialog.filtered = true" dense small elevation="0" v-on="on" v-bind="attrs">
                <v-icon color="#F05326">ri-filter-line</v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="actionExport()" dense small elevation="0" v-on="on" v-bind="attrs">
                <v-icon color="#4CAF50">ri-file-excel-2-line</v-icon>
              </v-btn>
            </template>
            <span>Donwload Excel</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="mb-10">
          <v-data-table
            :loading="process.run"
            disable-sort
            :headers="headers"
            :items="process.run ? [] : listData.list"
            :items-per-page.sync="listData.limit"
            :page.sync="listData.page"
            item-key="id"
            :mobile-breakpoint="0"
            :server-items-length="listData.total"
            loading-text="Loading... Please wait"
            hide-default-footer>
            <template slot="footer" slot-scope="{ props }">
              <v-row class="px-4">
                <div class="pa-3">
                  <v-row align="center">
                    <div>
                      <v-subheader
                        v-text="'Baris Per Halaman: '"
                      ></v-subheader>
                    </div>
                    <div>
                      <v-select
                        class="mt-0 pt-0"
                        style="width: 4rem"
                        v-model="listData.limit"
                        dense
                        :items="[10, 20, 50, 100]"
                        hide-details="auto"
                      >
                      </v-select>
                    </div>
                  </v-row>
                </div>
                <v-col>
                  <v-row class="mx-3" justify="end" align="center">
                    <div class="d-flex flex-nowrap align-center">
                      <v-subheader class="mr-2">Halaman:</v-subheader>
                      <v-select
                        v-model="listData.page"
                        dense
                        class="pa-0 ma-0 mr-2"
                        hide-details
                        :items="
                          Array.from(
                            {
                              length: Math.ceil(
                                listData.total / listData.limit
                              ),
                            },
                            (_, i) => i + 1
                          )
                        "
                        style="width: 5rem; text-align: center !important"
                      >
                      </v-select>
                      <v-subheader>
                        Menampilkan data ke
                        {{ props.pagination.pageStart + 1 }} sampai
                        {{ props.pagination.pageStop }} dari
                        {{ props.pagination.itemsLength }}
                      </v-subheader>
                    </div>
                    <v-btn
                      icon
                      small
                      :disabled="listData.page == 1"
                      @click="listData.page--"
                    >
                      <v-icon>$prev</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      small
                      :disabled="
                        listData.page ==
                        Math.ceil(listData.total / listData.limit)
                      "
                      @click="listData.page++"
                    >
                      <v-icon>$next</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </template>
            <template slot="item.member.fullname" slot-scope="{ item }">
              <span class="text-left">
                {{ item.member.fullname }}
              </span>
            </template>
            <template slot="item.member.place_of_birth" slot-scope="{ item }">
              <span class="text-center">
                {{ item.member.place_of_birth }}
              </span>
            </template>
            <template slot="item.member.age" slot-scope="{ item }">
              <div class="text-center">
                {{ item.member.age }} Tahun
              </div>
            </template>
            <template slot="item.start_test" slot-scope="{ item }">
              <div class="text-center">
                {{ item.start_test | datetime }}
              </div>
            </template>
            <template slot="item.end_test" slot-scope="{ item }">
              <div class="text-center">
                {{ item.end_test | datetime }}
              </div>
            </template>
            
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
import moment from "moment";
import XLSX from 'xlsx'
export default {
  components: {},
  data() {
    return {
      searching: "",
      hari: new Date().toISOString().substr(0, 10),
      form: {
        filter_place: "",
        filter_age_min: "",
        filter_age_max: "",
        filter_start_test: "",
        filter_end_test: "",
      },
      start_test_menu: false,
      end_test_menu: false,
      dialog: {
        filtered: false,
      },
      process: {
        run: false,
      },
      listData: {
        limit: 10,
        page: 1,
      },
      place: [],
      headers: [
        { text: "Nama", align: "left", value: "member.fullname", width: 250 },
        { text: "Asal", align: "center", value: "member.place_of_birth", width: 200 },
        { text: "Umur", align: "center", value: "member.age", width: 130 },
        { text: "Score Total", align: "center", value: "score" },
        { text: "Point Listening", align: "center", value: "section.listening.total_point" },
        { text: "Point Reading", align: "center", value: "section.reading.total_point" },
        { text: "Point Structure", align: "center", value: "section.structure.total_point" },
        { text: "Mulai Test", align: "center", value: "start_test", width: 200 },
        { text: "Selesai Test", align: "center", value: "end_test", width: 200 },
      ],
      notification: {
        state: false,
        text: "",
      },
      color: "",
    }
  },
  watch: {
    "listData.limit": function () {
      this.initialize();
    },
    "listData.page": function () {
      this.initialize();
    },
  },
  computed: {
    filter_start_test() {
      return this.form.filter_start_test ? moment(this.form.filter_start_test).format('DD MMMM YYYY') : ""
    },
    filter_end_test() {
      return this.form.filter_end_test ? moment(this.form.filter_end_test).format('DD MMMM YYYY') : ""
    }
  },
  created() {
    this.initialize()
  },
  mounted() {},
  methods: {
    ExportExcel() { // On Click Excel download button
      // console.log(this.listData);
      let json = []
      this.listData.list.forEach((item) => {
        json.push({
          "Nama": item.member.fullname,
          "Asal": item.member.place_of_birth,
          "Umur": item.member.age,
          "Score Total": item.score,
          "Point Listening" : item.section.listening.total_point,
          "Point Reading" : item.section.reading.total_point,
          "Point Structure" : item.section.structure.total_point,
          "Waktu Mulai Test" : item.start_test,
          "Waktu Selesai Test" : item.end_test,
        })
      })

      var wscols = [
      { width: 30 },  // first column width
      { width: 15 }, // second column width
      { width: 10 }, //...
      { width: 10 }, 
      { width: 10 },
      { width: 10 }, 
      { width: 10 }, 
      { width: 30 }, 
      { width: 30 }, 
    ];
    
      // export json to Worksheet of Excel
      // only array possible
      var PoinWS = XLSX.utils.json_to_sheet(json) 
      PoinWS['!cols'] = wscols;

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, PoinWS, 'data_point') // sheetAName is name of Worksheet
      // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')   

      // export Excel file
      XLSX.writeFile(wb, `Data_Point_${moment().format("DMYYYYHHmmss")}.xlsx`) // name of the file is 'book.xlsx'
      this.color = "#4CAF50"
      this.notification = {
        state: true,
        text: "Export Excel Berhasil",
      }
      this.listData.page = 1
      this.listData.limit = 10
      this.initialize('')
    },
    actionExport(){
      if (this.form.filter_place !== "" || this.form.filter_age_min !== "" || this.form.filter_age_max !== "" || this.form.filter_start_test !== "" || this.form.filter_end_test !== "") {
        this.listData.limit = this.listData.total; 
        this.initialize('','export')
      }else {
        this.color = "error"
        this.notification = {
          state: true,
          text: "Sebelum Export Silahkan Filter terlebih dahulu",
        }
      }
    },
    FormatAge(umur){
      var today = new Date();
      var birthday = new Date(umur);
      var year = 0;
      if (today.getMonth() < birthday.getMonth()) {
        year = 1;
      } else if ((today.getMonth() == birthday.getMonth()) && today.getDate() < birthday.getDate()) {
        year = 1;
      }
      var age = today.getFullYear() - birthday.getFullYear() - year;
  
      if(age < 0){
        age = 0;
      }

      return age
    },
    FormatTest(start_test){
      var today = new Date();
      var birthday = new Date(start_test);
      var year = 0;
      if (today.getMonth() < birthday.getMonth()) {
        year = 1;
      } else if ((today.getMonth() == birthday.getMonth()) && today.getDate() < birthday.getDate()) {
        year = 1;
      }
      var age_test = today.getFullYear() - birthday.getFullYear() - year;
  
      if(age_test < 0){
        age_test = 0;
      }

      return age_test
    },
    changeFrom(e){
      // // console.log(e);
      this.form.filter_age_min = e
    },
    changeTo(e){
      // // console.log(e);
      this.form.filter_age_max = e
    },
    resetFilter(){
      this.form = {
        filter_place: "",
        filter_age_min: "",
        filter_age_max: "",
        filter_start_test: "",
        filter_end_test: "",
      }
      this.initialize()
    },
    async initialize(searching,state){
      this.dialog.filtered = false;
      this.process.run = true;
      this.$set(this.listData, "list", []);

      this.$http.get("/users/v1/admin/order/point/list-member", {
          params: {
            q: searching,
            place_birth: this.form.filter_place,
            range_date_type: 'end_test',
            start_test: this.form.filter_start_test,
            end_test: this.form.filter_end_test,
            start_age: this.form.filter_age_min,
            end_age: this.form.filter_age_max,
            sort_by: "score",
            sort_type:"desc",
            limit: this.listData.limit,
            page: this.listData.page,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          let total = 0;
          res.data.list = res.data.list == null ? [] : res.data.list;
          this.listData = res.data;

          if (!this.listData.list.length) {
            this.process.run = false;
            return;
          }
          this.listData.list.forEach((item) => {
            this.place.push({text: item.member.place_of_birth})
              total++;
            if (total == this.listData.list.length) {
              if (state == 'export') {
                this.ExportExcel()
                this.process.run = false;
              }
              this.process.run = false;
            }
          });
        })
        .catch((error) => {
          console.error(error);
          this.process.run = false;
          this.dialog.filtered = false;
          this.certifLoading = false;
        });
    }
  }
}
</script>