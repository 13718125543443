// src/lib/vuetify.js
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'remixicon/fonts/remixicon.css'

Vue.use(Vuetify)

const MY_ICONS = {
  // menu
  dashboard: 'ri-dashboard-3-line',
  epot: 'ri-sticky-note-line',
  class: 'ri-artboard-line',
  practice: 'ri-honour-line',
  help: 'ri-stack-line',
  user: 'ri-user-line',
  certificate: 'ri-red-packet-line',
  certificate_b2b: 'ri-mail-star-line',
  send_certificate: 'ri-mail-send-line',
  statistic: 'ri-bar-chart-box-line',
  menu: 'ri-menu-line',
  sort: 'ri-arrow-down-line',

  // app-bar
  notification: 'ri-notification-2-line',
  dropdown: 'ri-arrow-down-s-line',
  
  //epot
  detail: 'ri-todo-line',
  publish: 'ri-upload-2-fill',
  archive: 'ri-archive-line',
  draft:'ri-draft-line',
  arrdown:'ri-arrow-down-s-fill',
  search:'ri-search-line',
  music:'ri-music-fill',
  image: 'ri-image-line',

  galery: 'ri-gallery-line',

  // action
  close: 'ri-close-fill',
  // duplicate: 'ri-file-copy-2-line',
  complete: 'ri-check-line',
  success: 'ri-checkbox-circle-line',
  info: 'ri-information-line',
  warning: 'ri-error-warning-line',
  error: 'ri-error-warning-fill',
  replace: 'ri-arrow-go-back-line',
  delete: 'ri-delete-bin-6-line',
  play: 'ri-play-fill',
  pause: 'ri-pause-fill',
  refresh: 'ri-refresh-line',
  
  checkboxOn:'ri-checkbox-fill',
  checkboxOff:'ri-checkbox-blank-line',
  chevronRight:'ri-arrow-right-s-line',
  // expand:'<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 9H0V18H9V16H2V9ZM9 2H16V9H18V0H9V2Z" fill="#4C6583"/></svg>',
  radioOff:'ri-checkbox-blank-circle-line',
  radioOn:'ri-radio-button-fill',
  next:'ri-arrow-right-s-line',
  prev:'ri-arrow-left-s-line',
  checkboxIndeterminate:'ri-checkbox-blank-line',
  expand:'ri-arrow-down-s-line',
  eye:'ri-eye-line',
  checkDouble:'ri-checkbox-multiple-line',
  print:'ri-printer-line'
}
// {
//   complete: '...',
//   cancel: '...',
//   close: '...',
//   delete: '...', // delete (e.g. v-chip close)
//   clear: '...',
//   success: '...',
//   info: '...',
//   warning: '...',
//   error: '...',
//   prev: '...',
//   next: '...',
//   checkboxOn: '...',
//   checkboxOff: '...',
//   checkboxIndeterminate: '...',
//   delimiter: '...', // for carousel
//   sort: '...',
//   expand: '...',
//   menu: '...',
//   subgroup: '...',
//   dropdown: '...',
//   radioOn: '...',
//   radioOff: '...',
//   edit: '...',
//   ratingEmpty: '...',
//   ratingFull: '...',
//   ratingHalf: '...',
//   loading: '...',
//   first: '...',
//   last: '...',
//   unfold: '...',
//   file: '...',
// }

const opts = {
  theme: {
    themes: {
      light: {
        background: '#F5F7F9',
        color: '#4C6583',
        primary: '#F05326',
      }
    },
    options: {
      customProperties: true
    },
  },
  icons: {
    values: MY_ICONS,
  },
}

export default new Vuetify(opts)
