<template>
  <v-card class="shadow-0" elevation="0" style="background: transparent">
    <!-- NOTIFICATION -->
    <v-snackbar :color="notification.color" top v-model="notification.state">
      <div v-html="notification.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="white"
          v-bind="attrs"
          @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-row v-if="process.run == false || searching != ''">
      <v-col cols="12" md="8" v-if="process.run == false || searching != ''">
        <div class="d-flex" style="width: 500px">
          <v-text-field
            v-model="searching"
            class="mr-3"
            outlined
            autocomplete="off"
            dense
            v-on:keyup.enter="initialize(searching,'')"
            prepend-inner-icon="ri-search-line"
            placeholder="Cari berdasarkan Nama . . ."
            hide-details="auto">
            <template v-slot:append v-if="searching">
              <v-icon @click="searching = '';initialize()">ri-close-line</v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="text-end">
        <v-btn
          color="#F05326"
          depressed
          v-if="process.run == false && listData.total > 0"
          dense
          to="/institution/form"
          class="white--text text-capitalize">
          Add New Lembaga
        </v-btn>
      </v-col>
    </v-row>

    <div class="text-center mt-7" v-if="process.run == false && listData.total < 1">
      <div class="mt-5">
        <v-img :src="require('@/assets/image/data_empty.png')" width="200" class="mx-auto"></v-img>
      </div>
      <div class="mt-3">Please add new Lembaga</div>
      <div class="mt-2">by click the button below</div>
      <v-btn
        color="#F05326"
        depressed
        dense
        to="/institusion/form"
        class="mt-3 white--text text-capitalize">
        Add New Lembaga
      </v-btn>
    </div>

    <div class="mt-3 mb-4" v-if="institusion_ids.length > 0">
      <v-btn
        elevation="0"
        color="red"
        dark
        small
        @click="deleteData">
        <v-icon left>$delete</v-icon>
        Hapus Lembaga
      </v-btn>
    </div>

    <div v-if="process.run" class="mt-3 mb-8">
      <v-skeleton-loader
        class="ma-auto"
        :loading="process.run"
        :tile="false"
        transition="scale-transition"
        type="table">
      </v-skeleton-loader>
    </div>

    <!-- # List Table -->
    <v-row v-if="process.run == false && listData.list.length > 0">
      <v-col cols="12">
        <v-card class="mb-10">
          <v-data-table
            show-select
            v-model="institusion_ids"
            item-key="id"
            :loading="process.run"
            disable-sort
            :headers="headers"
            :items="process.run ? [] : listData.list"
            :items-per-page.sync="listData.limit"
            :page.sync="listData.page"
            :mobile-breakpoint="0"
            :server-items-length="listData.total"
            loading-text="Loading... Please wait"
            hide-default-footer>
            <template slot="footer" slot-scope="{ props }">
              <v-row class="px-4">
                <div class="pa-3">
                  <v-row align="center">
                    <div>
                      <v-subheader
                        v-text="'Baris Per Halaman: '"
                      ></v-subheader>
                    </div>
                    <div>
                      <v-select
                        class="mt-0 pt-0"
                        style="width: 4rem"
                        v-model="listData.limit"
                        dense
                        :items="[10, 20, 50, 100]"
                        hide-details="auto"
                      >
                      </v-select>
                    </div>
                  </v-row>
                </div>
                <v-col>
                  <v-row class="mx-3" justify="end" align="center">
                    <div class="d-flex flex-nowrap align-center">
                      <v-subheader class="mr-2">Halaman:</v-subheader>
                      <v-select
                        v-model="listData.page"
                        dense
                        class="pa-0 ma-0 mr-2"
                        hide-details
                        :items="
                          Array.from(
                            {
                              length: Math.ceil(
                                listData.total / listData.limit
                              ),
                            },
                            (_, i) => i + 1
                          )
                        "
                        style="width: 5rem; text-align: center !important"
                      >
                      </v-select>
                      <v-subheader>
                        Menampilkan data ke
                        {{ props.pagination.pageStart + 1 }} sampai
                        {{ props.pagination.pageStop }} dari
                        {{ props.pagination.itemsLength }}
                      </v-subheader>
                    </div>
                    <v-btn
                      icon
                      small
                      :disabled="listData.page == 1"
                      @click="listData.page--"
                    >
                      <v-icon>$prev</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      small
                      :disabled="
                        listData.page ==
                        Math.ceil(listData.total / listData.limit)
                      "
                      @click="listData.page++"
                    >
                      <v-icon>$next</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </template>
            <template slot="item.name" slot-scope="{ item }">
              <span class="text-left">
                {{ item.name }}
              </span>
            </template>
            <template slot="item.address" slot-scope="{ item }">
              <div class="text-left">
                {{ item.address == '' ? '' : item.address }}
              </div>
            </template>
             <template slot="item.phone" slot-scope="{ item }">
              <div class="text-left">
                {{ item.phone == '' ? '-' : item.phone }}
              </div>
            </template>

            <template slot="item.action" slot-scope="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                    icon
                    text
                    small
                    :to="`/institution/member/${item.id}`">
                    <v-icon size="30">ri-add-box-fill</v-icon>
                  </v-btn>
                </template>
                <span>Tambah Peserta</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    color="blue"
                    text
                    icon
                    small
                    :to="`/institution/form?id=${item.id}`">
                    <v-icon size="30">ri-pencil-line</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
// import moment from "moment";
// import XLSX from 'xlsx'
export default {
  components: {},
  data() {
    return {
      institusion_ids: [],
      searching: "",
      // form: {
      //   filter_place: "",
      //   filter_age_min: "",
      //   filter_age_max: "",
      // },
      // dialog: {
      //   filtered: false,
      //   form: false,
      // },
      process: {
        run: false,
      },
      listData: {
        list: [],
        limit: 10,
        page: 1,
        total: 10,
      },
      headers: [
        { text: "Nama Lembaga", align: "left", value: "name", width: 250 },
        { text: "Alamat Lembaga", align: "left", value: "address", width: 250 },
        { text: "No. Telphone", align: "left", value: "phone", width: 100 },
        { text: "Aksi", align: "end", value: "action", width: 250 },
      ],
      notification: {
        state: false,
        text: "",
        color: ""
      },
    }
  },
  watch: {
    "listData.limit": function () {
      this.initialize();
    },
    "listData.page": function () {
      this.initialize();
    },
  },
  computed: {
    rules: () => ({
      required: (value) => !!value || "Tidak boleh kosong",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email tidak valid";
      },
    })
  },
  created() {
    this.$emit("page-changed", 0, { title: "Lembaga", link: "/institution" });
    this.initialize()
  },
  mounted() {},
  methods: {
    // ExportExcel() { // On Click Excel download button
    //   // console.log(this.listData);
    //   let json = []
    //   this.listData.list.forEach((item) => {
    //     json.push({
    //       "Nama": item.member.fullname,
    //       "Asal": item.member.place_of_birth,
    //       "Umur": item.member.age,
    //       "Score Total": item.score,
    //       "Point Listening" : item.section.listening.total_point,
    //       "Point Reading" : item.section.reading.total_point,
    //       "Point Structure" : item.section.structure.total_point
    //     })
    //   })

    //   var wscols = [
    //   { width: 30 },  // first column
    //   { width: 15 }, // second column
    //   { width: 10 }, //...
    //   { width: 10 }, 
    //   { width: 10 },
    //   { width: 10 }, 
    //   { width: 10 }, 
    // ];
    
    //   // export json to Worksheet of Excel
    //   // only array possible
    //   var PoinWS = XLSX.utils.json_to_sheet(json) 
    //   PoinWS['!cols'] = wscols;
    //   // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons) 

    //   // A workbook is the name given to an Excel file
    //   var wb = XLSX.utils.book_new() // make Workbook of Excel

    //   // add Worksheet to Workbook
    //   // Workbook contains one or more worksheets
    //   XLSX.utils.book_append_sheet(wb, PoinWS, 'animals') // sheetAName is name of Worksheet
    //   // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')   

    //   // export Excel file
    //   XLSX.writeFile(wb, `Data_Point.xlsx`) // name of the file is 'book.xlsx'
    //   this.notification.state = true,
    //   this.notification.color = "#4CAF50"
    //   this.notification.text =  "Export Excel Berhasil",
    //   this.listData.page = 1
    //   this.listData.limit = 10
    //   this.initialize('')
    // },
    // actionExport(){
    //   if (this.form.filter_place !== "" || this.form.filter_age_min !== "" || this.form.filter_age_max !== "") {
    //     this.listData.limit = this.listData.total; 
    //     this.initialize('','export')
    //   }else {
    //     this.notification.state = true,
    //     this.notification.color = "error"
    //     this.notification.text =  "Sebelum Export Silahkan Filter terlebih dahulu"
    //   }
    // },
    // resetForm(item, status){
    //   this.dialog.form = status

    //   this.form = {
    //     id: item == undefined ? "" : item.id,
    //     form_status:  item == undefined ? "add" : "update",
    //     name: item == undefined ? "" : item.name,
    //     chairman: item == undefined ? "" : item.chairman,
    //     phone: item == undefined ? "" : item.phone,
    //     email: item == undefined ? "" : item.email,
    //     address: item == undefined ? "" : item.address,
    //     active_camera: item == undefined ? true : item.active_camera,
    //     identification_tab: item == undefined ? true : item.identification_tab,
    //     setting : {
    //       identification_face : item == undefined ? true : item.setting.identification_face,
    //       identification_voice :  item == undefined ? true : item.setting.identification_voice
    //     }
    //   }

    //    this.$refs.observer.reset()
    // },
    // resetFilter(){
    //   this.form = {
    //     filter_place: "",
    //     filter_age_min: "",
    //     filter_age_max: "",
    //   }
    //   this.initialize()
    // },
    
    // # METHOD API AXIOS
    deleteData(){
      let data = []
      this.institusion_ids.forEach((item) => {
        data.push(item.id)
      })
      this.deleteInstitution(data)
    },

    async deleteInstitution(ids){
      this.process.run = true
      let data = {
        institusion_ids: ids
      }
      await this.axios.post(`/institusion/v1/delete/institusions`, data).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.process.run = false
          this.dialog.form = false
          this.notification.state = true;
          this.notification.color = "#4CAF50";
          this.notification.text = "Lembaga berhasil di hapus";
        }else {
          this.process.run = false
          this.dialog.form = false
        }
      }).catch(error => {
        let message = error.message;
        if (error.response) {
          message = error.response.data.message;
        }

        this.notification.state = true;
        this.notification.color = "error";
        this.notification.text = "Error: " + message;
        console.error(error);
        this.process.run = false
      })
    },

    async initialize(searching){
      // this.dialog.filtered = false;
      this.process.run = true;
      // this.$set(this.listData, "list", []);

      this.axios.get("/institusion/v1/get/institusion/list", {
          params: {
            q: searching,
            limit: this.listData.limit,
            page: this.listData.page,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          if (res.status === 200) {
            res.data.institusions = res.data.institusions == null ? [] : res.data.institusions;
            this.listData.list = res.data.institusions;
            this.listData.total = res.data.count;
            this.process.run = false;
          }
        })
        .catch((error) => {
          console.error(error);
          this.process.run = false;
          // this.dialog.filtered = false;
          this.certifLoading = false;
        });
    }
  }
}
</script>