<template>
  <v-row justify="center">
    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialog.filtered" scrollable persistent width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-1 text-first d-flex mb-4"
          style="background-color: #f05326; height: 50px"
        >
          <span class="white--text"> Dialog Filter </span>
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.filtered = false" color="white"
            >ri-close-line</v-icon
          >
        </v-card-title>
        <v-card-text style="height: 350px" class="py-5">
          <v-text-field
            v-model="filter.code"
            outlined
            class="mb-5"
            placeholder="Masukkan kode lisensi"
            hide-details="auto">
          </v-text-field>
          <v-select
            filled
            placeholder="Pilih Status digunakan"
            v-model="filter.is_used"
            :items="is_used"
            outlined>
            <template slot="item" slot-scope="{ item }">
              <div class="text-capitalize">{{ item.text }}</div>
            </template>
          </v-select>
          <v-select
            filled
            v-model="filter.is_sold"
            placeholder="Pilih Status pesanan"
            :items="is_sold"
            outlined>
            <template slot="item" slot-scope="{ item }">
              <div class="text-capitalize">{{ item.text }}</div>
            </template>
          </v-select>
          <v-autocomplete
            filled
            v-model="filter.member_id"
            placeholder="Pilih Pengguna"
            :items="memberData"
            :loading="isMemberLoading"
            :search-input.sync="searchMemberData"
            hide-no-data
            outlined
            item-text="email"
            item-value="id">
            <!-- <template slot="item" slot-scope="{ item }">
              <div class="text-capitalize">{{ item.text }}</div>
            </template> -->
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            width="100"
            color="#F05326"
            elevation="0"
            :disabled="process.run"
            :loading="process.run"
            class="white--text text-capitalize"
            @click="search()"
          >
            Filter
          </v-btn>
          <v-btn
            width="100"
            elevation="0"
            color="yellow darken-2"
            class="text-capitalize white--text"
            :disabled="process.run"
            @click="
              filter.code = '';
              filter.is_sold = '';
              filter.is_used = '';
              filter.member_id = '';
              $store.commit('license_order/SET_PARAMS', {});
              fetchData();
            "
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-col md="6">
      <v-card elevation="0" outlined>
        <v-col>
          <v-form ref="form" @submit.prevent="search">
            <v-row no-gutters class="flex-nowrap" align="start">
              <v-text-field
                v-model="code"
                class="mr-3"
                outlined
                filled
                placeholder="Masukkan kode lisensi"
                hide-details="auto"
                :rules="[(v) => !!v || 'Kode lisensi tidak boleh kosong']">
              </v-text-field>
              <v-btn
                color="primary"
                elevation="0"
                :loading="isSearching"
                type="submit">
                <span> Cari </span>
              </v-btn>
              <v-btn
                v-if="Object.keys(params).length"
                class="ml-2"
                outlined
                color="primary"
                elevation="0"
                @click="
                  $refs.form.reset();
                  $store.commit('license_order/SET_PARAMS', {});
                  fetchData();">
                <span> Reset </span>
              </v-btn>
            </v-row>
          </v-form>
        </v-col>
      </v-card>
    </v-col> -->
    <v-col cols="12">
      <v-row justify="end" class="mb-5">
        <v-col cols="12" md="5">
          <div class="d-flex">
            <v-text-field
              v-model="searching"
              outlined
              dense
              prepend-inner-icon="ri-search-line"
              placeholder="Cari Lisensi Pengguna . . ."
              hide-details="auto"
            >
              <template v-slot:append v-if="searching">
                <v-icon @click="searching = ''">ri-close-line</v-icon>
              </template>
            </v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="dialog.filtered = true"
                  dense
                  small
                  elevation="0"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon color="#F05326">ri-filter-line</v-icon>
                </v-btn>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-card elevation="0" outlined>
        <v-data-table
          :headers="headers"
          :items="data.list || []"
          :items-per-page.sync="data.limit"
          :page.sync="data.page"
          :server-items-length="data.total"
          :loading="dataLoading"
          hide-default-footer
          disable-filtering
          disable-sort
        >
          <template slot="item.code" slot-scope="{ item }">
            <span>{{ item.license }}</span
            ><br />
            <v-chip v-if="validity(item)" small>{{ validity(item) }}</v-chip>
          </template>
          <template slot="item.order_no" slot-scope="{ item }">
            <span>{{ item.order_no || "-" }}</span>
          </template>
          <template slot="item.buyer" slot-scope="{ item }">
            <span v-if="item.member.email">{{ item.member.email }}<br /></span>
            <span class="color-navy-soft" v-if="item.member.full_name"
              >{{ item.member.full_name }}<br
            /></span>
          </template>
          <template slot="item.user" slot-scope="{ item }">
            <span v-if="item.member.email">{{ item.member.email }}<br /></span>
            <span class="color-navy-soft" v-if="item.member.full_name"
              >{{ item.member.full_name }}<br
            /></span>
          </template>
          <template slot="item.action" slot-scope="{ item }">
            <div class="d-flex flex-nowrap">
              <!-- #BUTTON DETAIL -->
               <v-tooltip bottom>
                <v-btn
                  small
                  slot="activator"
                  slot-scope="{ on, attrs }"
                  v-on="on"
                  v-bind="attrs"
                  elevation="0"
                  color="primary"
                  class="mr-1"
                  icon
                  @click="detail_license(item)"
                >
                  <v-icon>ri-information-line</v-icon>
                </v-btn>
                <span>View Detail</span>
              </v-tooltip>

              <!-- #BUTTON DELETE -->
              <v-tooltip bottom>
                <v-btn
                  small
                  slot="activator"
                  slot-scope="{ on, attrs }"
                  v-on="on"
                  v-bind="attrs"
                  elevation="0"
                  color="primary"
                  class="mr-1"
                  icon
                  @click="action(item, 'delete')"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
          <template slot="footer" slot-scope="{ props }">
            <v-row class="px-4">
              <div class="pa-3">
                <v-row align="center">
                  <div>
                    <v-subheader v-text="'Row per page: '"></v-subheader>
                  </div>
                  <div>
                    <v-select
                      class="mt-0 pt-0"
                      style="width: 4rem"
                      v-model="data.limit"
                      :items="[10, 20, 50, 100]"
                      hide-details="auto"
                    ></v-select>
                  </div>
                </v-row>
              </div>
              <v-col>
                <v-row class="mx-3" justify="end" align="center">
                  <div class="d-flex flex-nowrap align-center">
                    <v-subheader class="mr-2">Halaman:</v-subheader>
                    <v-select
                      v-model="data.page"
                      dense
                      solo
                      class="pa-0 ma-0 mr-2"
                      hide-details
                      :items="
                        Array.from(
                          { length: Math.ceil(data.total / data.limit) },
                          (_, i) => i + 1
                        )
                      "
                      style="width: 5rem"
                    ></v-select>
                    <v-subheader>
                      Menampilkan data ke
                      {{ props.pagination.pageStart + 1 }} sampai
                      {{ props.pagination.pageStop }} dari
                      {{ props.pagination.itemsLength }}
                    </v-subheader>
                  </div>
                  <v-btn
                    icon
                    small
                    :disabled="data.page == 1"
                    @click="data.page--"
                  >
                    <v-icon>$prev</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    :disabled="data.page == Math.ceil(data.total / data.limit)"
                    @click="data.page++"
                  >
                    <v-icon>$next</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
        <v-row v-show="false">
          <v-col md="6" offset-md="3">
            <div class="title text-center">
              <v-img
                src="@/assets/images/data_empty.png"
                width="66.667%"
                class="mb-4 mx-auto"
              ></v-img>
              Lisensi tidak ditemukan
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog.detail_license" scrollable persistent transition="dialog-bottom-transition" width="75%">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-1 text-first d-flex mb-4"
          style="background-color: #f05326; height: 50px">
          <span class="white--text"> Dialog Detail License {{ detail.code }} </span>
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.detail_license = false" color="white">ri-close-line</v-icon>
        </v-card-title>
        <v-card-text style="height:600px">

          <v-row v-if="Object.keys(detail).length > 0">
            <v-col cols="12" md="6">
              <!-- ==== INFORMASI PRODUK ==== -->
              <v-card flat>
                <div class="text-h6 font-weight-regular mb-5 text-second">
                  Informasi Buyer
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr>
                        <th class="text-second pl-0" width="30%">Courier</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.courier == '' ? '-' : detail.buyer.courier }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Courier Type</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.courier_type == '' ? '-' : detail.buyer.courier_type }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Buyer FullName</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.fullname == '' ? '-' : detail.buyer.fullname }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Buyer Email</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.email == '' ? '-' : detail.buyer.email }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Catatan Order</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.order_note == '' ? '-' : detail.buyer.order_note }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Penerima</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.receiver == '' ? '-' : detail.buyer.receiver }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Alamat Penerima</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.receiver_address == '' ? '-' : detail.buyer.receiver_address }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">No Telepon</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.receiver_phone == '' ? '-' : detail.buyer.receiver_phone }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Kode Pos</th>
                        <td width="5%" class="text-center text-second">:</td>
                        <td class="text-right text-second">
                          {{ detail.buyer.receiver_post_code == '' ? '-' : detail.buyer.receiver_post_code }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <!-- ==== RINGKASAN PERSEDIAAN ==== -->
              <v-card flat>
                <div class="text-h6 font-weight-regular mb-5 text-second">
                  Ringkasan Item Buyer
                </div>
                <v-simple-table class="px-3">
                  <template>
                    <tbody>
                      <tr>
                        <th class="text-second pl-0" width="30%">Sertificate Type</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.is_fisik == '' ? '-' : detail.buyer.is_fisik }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Order ID</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.wp_order_id == '' ? '-' : detail.buyer.wp_order_id }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Name</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.name == '' ? '-' : detail.buyer.item.name }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Height</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.height | num_format }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Lenght</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.length | num_format }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Quantity</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.quantity | num_format }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Value</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.value | num_format }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Weight</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.weight | num_format }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-second pl-0" width="30%">Item Width</th>
                        <td width="5%" class="text-center">:</td>
                        <td class="text-right">
                          {{ detail.buyer.item.width | num_format }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalBooking" width="500" scrollable>
      <v-form ref="bookingForm" @submit.prevent="booking">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Booking lisensi</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 500px">
            <v-row>
              <v-col cols="12">
                <span>Nama Lengkap</span>
                <v-text-field
                  v-model="bookingData.fullname"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Email</span>
                <v-text-field
                  v-model="bookingData.email"
                  type="email"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Order ID</span>
                <v-text-field
                  v-model="bookingData.wp_order_id"
                  type="number"
                  outlined
                  filled
                  hide-details="auto"
                  :rules="[rules.required, rules.wp_order_id]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Courier</span>
                <v-text-field
                  v-model="bookingData.courier"
                  type="text"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Courier Type</span>
                <v-text-field
                  v-model="bookingData.courier_type"
                  type="text"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Receiver</span>
                <v-text-field
                  v-model="bookingData.receiver"
                  type="text"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Receiver Phone</span>
                <v-text-field
                  v-model="bookingData.receiver_phone"
                  type="number"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Receiver Address</span>
                <v-text-field
                  v-model="bookingData.receiver_address"
                  type="text"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Receiver Post Code</span>
                <v-text-field
                  v-model="bookingData.receiver_post_code"
                  type="number"
                  outlined
                  filled
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <span>Type Order Sertifikat</span>
                <v-checkbox
                  color="orange darken-3"
                  v-model="bookingData.is_fisik"
                  true-value="1"
                  false-value="0"
                  hide-details
                  label="Fisik Sertifikat"
                >
                  <!-- <template v-slot:label>
                    <div>Fisik Sertifikat</div>
                  </template> -->
                </v-checkbox>
              </v-col>
            </v-row>
            <!-- ITEMS -->
            <v-row v-if="bookingData.is_fisik == 1">
              <div class="ml-5 body-1">Data Items</div>
              <v-col cols="12">
                <div>
                  Item Name
                </div>
                <v-text-field
                  v-model="bookingData.item.name"
                  outlined
                  filled
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Value
                </div>
                <v-text-field
                  v-model="bookingData.item.value"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Quantity
                </div>
                <v-text-field
                  v-model="bookingData.item.quantity"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Height
                </div>
                <v-text-field
                  v-model="bookingData.item.height"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Length
                </div>
                <v-text-field
                  v-model="bookingData.item.length"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Weight
                </div>
                <v-text-field
                  v-model="bookingData.item.weight"
                  outlined
                  filled
                  type="number"
                  min="0"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <div>
                  Width
                </div>
                <v-text-field
                  v-model="bookingData.item.width"
                  outlined
                  filled
                  min="0"
                  type="number"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="
              $refs.bookingForm.reset();
                modalBooking = null;
                license = {};
              "
              >Batal</v-btn
            >
            <v-btn
              @click="booking"
              color="primary"
              elevation="0"
              :loading="isBooking"
              >Kirim</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="modalDestroy" width="500">
      <v-form ref="destroyForm" @submit.prevent="destroy()">
        <v-card outlined elevation="0">
          <v-card-title>
            <span>Hapus lisensi</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            Apakah anda yakin ingin menghapus lisensi
            <strong>{{ license.code }}</strong> ini?
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              @click="
                modalDestroy = null;
                license = {};
              "
              >Batal</v-btn
            >
            <v-btn type="submit" color="primary" elevation="0">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar v-model="toast.state" top :color="toast.color">
      {{ toast.message }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Cek-lisensi",
  data: () => ({
    isSearching: false,
    isBooking: false,
    isDeleting: false,
    dataLoading: null,
    isMemberLoading: false,
    is_used: [
      {
        text: "Digunakan", 
        value: 1,
      },
      {
        text: "Belum digunakan", 
        value: 0,
      }
    ],
    is_sold: [
      {
        text: "Dipesan", 
        value: 1,
      },
      {
        text: "Belum dipesan", 
        value: 0,
      }
    ],
    memberData: [],
    searchMemberData: null,
    filter: {
      is_used: "",
      is_sold: "",
      code: "",
      member_id: "",
    },
    searching: "",
    license: {},
    detail: {},
    bookingData: {
      email: "",
      fullname: "",
      is_fisik: 0,
      wp_order_id: "",
      courier: "",
      courier_type: "",
      receiver: "",
      receiver_phone: "",
      receiver_address: "",
      receiver_post_code: "",
      item : {
        name: "",
        value: 0,
        quantity: 0,
        height: 0,
        length: 0,
        weight: 0,
        width: 0
      }
    },
    modalDestroy: null,
    modalBooking: null,
    dialog: {
      filtered: false,
      detail_license: false,
    },
    toast: {
      state: false,
      color: "",
      message: ""
    },
    process: {
      run: false,
    },
  }),
  computed: {
    rules: () => ({
      required: (value) => !!value || "Tidak boleh kosong",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email tidak valid";
      },
    }),
    headers: () => [
      { text: "ID Sertifikat", value: "order_no" },
      { text: "Kode Lisensi", value: "code" },
      { text: "Pengguna", value: "user" },
      { text: "Action", value: "action" },
    ],
    ...mapGetters({ data: "license_order/data", params: "license_order/params" }),
  },
  created() {
    this.fetchData();
  },
  methods: {
    // # FUNCTION DETAIL
    detail_license(data){
      // console.log(data);
      this.detail = data
      this.dialog.detail_license =  true
    },

    fetchData() {
      this.dataLoading = true;
      this.$store.dispatch("license_order/list",{
        // member_id:,
        limit:10,
        page:1,
        sort_by:'created_at',
        sort_type:'desc',
        // order_id:'',
        }).finally(() => {
        this.dataLoading = false;
      });
    },
    search() {
      // if (!this.$refs.form.validate()) {
      //   return;
      // }
      this.dataLoading = true;
      this.isSearching = true;
      this.process.run = true;
      this.dialog.filtered = false;
      this.$store
        .dispatch("license_order/list", { 
          code: this.filter.code,
          is_used: this.filter.is_used,
          is_sold: this.filter.is_sold,
          member_id: this.filter.member_id,
          page: 1 
        })
        .finally(() => {
          this.fetchData()
          this.dataLoading = false;
          this.isSearching = false;
          this.process.run = false;
        });
    },
    action(item, action) {
      this.license = item;
      switch (action) {
        case "delete":
          this.modalDestroy = true;
          break;
        case "book":
          this.modalBooking = true;
          break;
      }
    },
    booking() {
      if (this.$refs.bookingForm.validate()) {
        this.isBooking = true;
        this.$store
        .dispatch("license_order/book", {
          code: this.license.code,
          email: this.bookingData.email,
          fullname: this.bookingData.fullname,
          is_fisik: parseInt(this.bookingData.is_fisik),
          wp_order_id: parseInt(this.bookingData.wp_order_id),
          courier: this.bookingData.courier,
          courier_type: this.bookingData.courier_type,
          receiver: this.bookingData.receiver,
          receiver_phone: this.bookingData.receiver_phone,
          receiver_address: this.bookingData.receiver_address,
          receiver_post_code: parseInt(this.bookingData.receiver_post_code),
          item: {
            name: this.bookingData.item.name,
            value: parseInt(this.bookingData.item.value),
            quantity: parseInt(this.bookingData.item.quantity),
            height: parseInt(this.bookingData.item.height),
            length: parseInt(this.bookingData.item.length),
            weight: parseInt(this.bookingData.item.weight),
            width: parseInt(this.bookingData.item.width)
          }
        })
        .then((response) => {
          console.log(response);
          this.$set(this.toast, "state", true);
          this.$set(this.toast, "color", "#4CAF50");
          this.$set(this.toast, "message", "Lisensi berhasil dibooking");
        })
        .catch((error) => {
          let message = error.message;
          if (error.response) {
            message = error.response.data.message;
          }
          this.$set(this.toast, "state", true);
          this.$set(this.toast, "color", "error");
          this.$set(this.toast, "message", "Error: " + message);
        })
        .finally(() => {
          this.isBooking = false;
          this.modalBooking = false;
          this.code = "";
          this.$refs.form.reset();
        });
      }
    },
    destroy() {
      if (this.$refs.destroyForm.validate()) {
        this.isDeleting = true;
        this.$store
          .dispatch("license_order/delete",  {
          order_id: [this.license.id],
          })
          .then(() => {
            this.$set(this.toast, "state", true);
            this.$set(this.toast, "color", "#4CAF50");
            this.$set(this.toast, "message", "Lisensi berhasil dihapus");
          })
          .catch((error) => {
            let message = error.message;
            if (error.response) {
              message = error.response.data.message;
            }
            this.$set(this.toast, "state", true);
            this.$set(this.toast, "color", "error");
            this.$set(this.toast, "message", "Error: " + message);
          })
          .finally(() => {
            this.isDeleting = false;
            this.modalDestroy = false;
            this.$refs.form.reset();
          });
      }
    },
    dateTimeFormat(date) {
      date = new Date(date);
      return date.toLocaleString("ID").replaceAll("/", "-");
    },
    dateFormat(date) {
      date = new Date(date);
      return date.toLocaleDateString("ID");
    },
    validity(item) {
      if (item.start_date && item.end_date) {
        return `Berlaku mulai ${this.dateTimeFormat(
          item.start_date
        )} s/d ${this.dateTimeFormat(item.end_date)}`;
      } else if (item.start_date) {
        return `Berlaku mulai ${this.dateTimeFormat(item.start_date)}`;
      } else if (item.end_date) {
        return `Berlaku sampai dengan ${this.dateTimeFormat(item.end_date)}`;
      } else {
        return "";
      }
    },
  },
  watch: {
    searchMemberData(val) {
      // Items request
      if (this.isMemberLoading) return
      this.isMemberLoading = true
      // Lazily load input items
      this.$http
        .get("/users/v1/admin/member", {
          params: {
            q: val,
            limit: 10,
          },
        })
        .then(res => {
          const data = res.data.data
          this.memberData = data.list
        })
        .finally(() => (this.isMemberLoading = false))
    },
    searching: function (newval) {
      setTimeout(() => {
        this.$store
          .dispatch("license_order/list", { q: newval, page: 1 })
          .finally(() => {
            this.dataLoading = false;
            this.isSearching = false;
          });
      }, 800);
    },
    "data.limit": function () {
      this.fetchData();
    },
    "data.page": function () {
      this.fetchData();
    },
  },
};
</script>