<template>
  <body style="width: 100%;margin-left: -24px;">
    <v-row>
      <section
        class="head text-center"
        style="
          width: 100%;
          font-family: arial;
          margin-bottom: 0px;
          margin-top: 23px;
          margin-right: 75px;
        "
      >        
        <table style="width: 90%; margin: auto;margin-left: auto; margin-right: auto;">
          <tr>
            <td style="width: 13%; text-align: center;padding:0px">
              <img src="@/assets/images/logo-certif-new.png" style="width: 115px;height: 115px;margin-top: 0px;margin-left: 15px;" />
            </td>
            <td style="width: 86%; text-align: center">
              <p style="font-size: 20px;font-weight: 500;line-height: 0em;margin-bottom:24px;margin-right:70px;">
                LEMBAGA KURSUS DAN PELATIHAN
              </p>
              <p style="font-size: 24px;font-weight: 700;margin-bottom:12px;margin-right:70px;">
                YUREKA EDUCATION CENTER
              </p>
              <span style="font-size: 13px;font-weight: 400;margin-right:70px;line-height: 1em;">
                Izin Pendirian LKP YEC Nomor: 503/93175-PEND/93175-20231122/LKP/2023
              </span>
              <br />
              <span style="font-size: 13px;font-weight: 400;margin-right:70px;">
                Nomor Pokok Sekolah Nasional (NPSN) Nomor K9990451
              </span>
            </td>
          </tr>
          <tr>
            <td style="width: 10%; text-align: center"></td>
            <td style="width: 95%; text-align: center;">              
              <p style="font-size: 20px; font-weight: 700;margin-right: 75px;margin-top:45px;line-height: 0em;">
                CERTIFICATE OF ACHIEVEMENT
              </p>

              <p style="font-size: 13px;font-weight: 400; margin-bottom: 20px;margin-right:75px;">
                ID Certificate :
                <span class="text-uppercase">{{ data.order_no }}</span>
              </p>
              <p></p>
              <p style="margin-bottom: 20px;font-size: 13px;font-weight: 400;margin-right:75px;margin-bottom: 15px;">This is to certify that</p>
              <table style="width:95%;font-size: 14px;font-weight: 400;margin-right:285px;margin-bottom: 30px;text-align:center;margin-left: auto;">
                <tr>
                  <td style="padding: 1px;width:41%;text-align: right;margin-right:25px;">Name </td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;width:55%;text-align: left;margin-right:25px;"> {{ data.member ? data.member.fullname : "" }}</td>
                </tr>
                <tr style="background: #fff;">
                  <td style="padding: 1px;width:41%;text-align: right;margin-right:25px;">Date of Birth </td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;width:55%;text-align: left;margin-right:25px;">{{
                    date_format(data.member ? data.member.date_of_birth : "")
                  }}</td>
                </tr>
                <tr>
                  <td style="padding: 1px;width:41%;text-align: right;margin-right:25px;">Test Date </td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;width:55%;text-align: left;margin-right:25px;">{{ date_format(data.start_test) }}</td>
                </tr>
              </table>
              <p style="font-size: 14px;font-weight: 400;margin-bottom: 10px;margin-right:75px;">
                has taken <b>English Proficiency Online Test</b> and achieved
                the following scores
              </p>
              <table
                style="
                  width:49%;font-size: 14px;font-weight: 400;margin-bottom: 10px;
                "
              >
                <tr>
                  <td style="padding: 1px;text-align: right;margin-right:25px;">Reading</td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;text-align: left;margin-right:25px;">
                    {{ data.section ? data.section.reading.total_point : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 1px;text-align: right;margin-right:25px;">Structure</td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;text-align: left;margin-right:25px;">
                    {{ data.section ? data.section.structure.total_point : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 1px;text-align: right;margin-right:25px;">Listening</td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;text-align: left;margin-right:25px;">
                    {{ data.section ? data.section.listening.total_point : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 1px;text-align: right;margin-right:25px;"><b>Total Correct Point</b></td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;text-align: left;margin-right:25px;">{{ correct }}</td>
                </tr>
                <tr>
                  <td style="padding: 1px;text-align: right;margin-right:25px;">
                    <b>YEC Prediction Score for TOEFL</b>
                  </td>
                  <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                  <td style="padding: 1px;text-align: left;margin-right:25px;">
                    {{ data.score }}
                  </td>
                </tr>
              </table>
              <table style="font-size: 14px;font-weight: 700;margin-bottom: 15px;margin-left: auto; margin-right: 290px;">
                  <tr>
                    <td style="padding: 1px;width:51%;text-align: right;margin-right:25px;">Proficiency Level </td>
                    <td style="padding: 1px;width:2%;text-align: center;margin-right:25px;">:</td>
                    <td style="padding: 1px;width:55%;text-align: left;margin-right:25px;"> {{ data.level }}</td>
                  </tr>
              </table>              
            </td>
          </tr>
        </table>
      </section>
      <section class="foot" style="width: 100%;font-family: arial;margin-top: 10px;margin-bottom:0px;">
        <table style="width: 97%; margin: auto">
          <tr>
            <td style="width: 19%; text-align: center">
              <p style="font-size: 8px; font-weight: 400">
                ID Certificate:
                <span class="text-uppercase">{{ data.order_no }}</span>
              </p>
              <vue-qrcode
                :value="`https://www.yec.co.id/review-sertifikat-epot/?id=${data.license}`"
                errorCorrectionLevel="H"
                :width="120"
                :margin="3"
                :padding="6"
                style="border:1px solid;"
              />
              <p style="font-size: 8px; font-weight: 400">
                scan me to verify this certificate
              </p>
            </td>
            <td style="width: 60%">
              <p style="font-size: 14px;font-weight: 400;margin-bottom: 25px;margin-top: 10px;">
                This certificate is acceptable until
                <b>{{ expired }}</b>
              </p>
              <p
                style="font-size: 14px;font-weight: 400;line-height: 0.25rem"
              >
                TOEFL is a registered trademark of Educational Testing Service (ETS),
              </p>
              <p
                style="font-size: 14px; font-weight: 400; line-height: 0.25rem"
              >
                This document is not endorsed or approved by ETS
              </p>
            </td>
            <td style="width: 55%; text-align: center;">
              <!-- <img src="@/assets/images/logo_ttd.png"/> -->
              <p style="font-size: 16px; font-weight: 500">Director,</p>
              <br />
              <br />
              <br />
              <br />
              <p style="font-size: 16px; font-weight: 500">
                M. Animna Husna, S.I.A
              </p>
            </td>
          </tr>
        </table>
      </section>
      <section
        class="next"
        style="width: 100%; font-family: arial;margin-bottom: 35px;padding-top: 2px;"
      >
        <div style="width: 90%; margin: auto">
          <p style="font-size: 22px; font-weight: 700;">
            Certificate Verification
          </p>
          <div style="width: 65%; margin-bottom: 20px">
            <p
              style="
                font-size: 13px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Each certificate has unique QR code. Please scan the QR Code by
              using QR Code scanner application and click the link appeared
              after scanning. The link will lead you to a website page that
              presents the exact information as written on the certificate.If
              the information on this certificate is not the same as on the
              website page; especially the name and the score; this certificate
              is considered as counterfeit or not valid.
            </p>
            <p style="font-size: 24px;font-weight: 700;margin-bottom: 45px;">Proficiency Level</p>
            <table style="border-collapse: collapse;width: 80%;margin-bottom: 10px;color: rgba(0, 0, 0, 0.5);">
              <tr>
                <th
                  style="
                    border: 0.5px solid #00000080;
                    text-align: left;
                    padding: 4px;
                  "
                >
                  Score
                </th>
                <th
                  style="
                    border: 0.5px solid #00000080;
                    text-align: left;
                    padding: 4px;
                  "
                >
                  Proficiency Level
                </th>
              </tr>
              <tr>
                <td style="border: 0.5px solid #00000080; padding: 4px">
                  310 - 400
                </td>
                <td style="border: 0.5px solid #00000080; padding: 4px">
                  Beginner
                </td>
              </tr>
              <tr>
                <td style="border: 0.5px solid #00000080; padding: 4px">
                  403 - 460
                </td>
                <td style="border: 0.5px solid #00000080; padding: 4px">
                  Intermediate
                </td>
              </tr>
              <tr>
                <td style="border: 0.5px solid #00000080; padding: 4px">
                  463 - 520
                </td>
                <td style="border: 0.5px solid #00000080; padding: 4px">
                  Upper intermediate
                </td>
              </tr>
              <tr>
                <td style="border: 0.5px solid #00000080; padding: 4px">
                  523 - 677
                </td>
                <td style="border: 0.5px solid #00000080; padding: 4px">
                  Advanced
                </td>
              </tr>
            </table>
          </div>
          <span style="font-size: 13px; font-weight: 400; margin-bottom: 0px;line-height: 1em;">
            Issued by Yureka Education Center
          </span><br />
          <span style="font-size: 13px; font-weight: 400">yec.co.id</span>
        </div>
      </section>
    </v-row>
  </body>
</template>
<script>
import VueQrcode from "vue-qrcode";

export default {
  name: "template-certificate-cetak",
  components: { VueQrcode },
  props: {
    data: { type: Object, default: () => ({}) },
  },
  computed: {
    correct() {
      let section = this.data.section;
      return section
        ? `${
            section.listening.total_point +
            section.reading.total_point +
            section.structure.total_point
          }`
        : "";
    },
    expired() {
      let expired = new Date(this.data.end_test);
      expired.setFullYear(expired.getFullYear() + 1);
      return this.date_format(expired);
    },
  },
  methods: {
    date_format(raw_date) {
      let date = new Date(raw_date);

      return Date.parse(raw_date)
        ? `${date.toLocaleString("default", {
            month: "long",
          })} ${date.getDate()}, ${date.getFullYear()}`
        : "";
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700");
</style>