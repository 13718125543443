import { render, staticRenderFns } from "./CaptureDetail.vue?vue&type=template&id=6c634396&scoped=true&"
import script from "./CaptureDetail.vue?vue&type=script&lang=js&"
export * from "./CaptureDetail.vue?vue&type=script&lang=js&"
import style0 from "./CaptureDetail.vue?vue&type=style&index=0&id=6c634396&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c634396",
  null
  
)

export default component.exports