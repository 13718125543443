<template>
  <v-row align-content="center">
    <v-col class="py-0" md="6">
      <v-row>
        <v-col class="white px-12" md="11" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <v-form
            ref="form"
            class="px-8"
            @submit.prevent="submit"
            v-model="valid"
          >
            <div class="headline mb-4">Welcome back to YEC</div>
            <v-row>
              <v-col>
                <div class="subtitle-2 mb-2">Email</div>
                <v-text-field
                  filled
                  v-model="email"
                  placeholder="Masukkan email"
                  type="email"
                  :rules="[...rules('Email', 'required'), validate]"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div class="mb-2">
                  <span class="subtitle-2">Password</span>
                  <router-link
                    :to="{ name: 'forgot-password' }"
                    class="subtitle-2 float-right text-decoration-none"
                    tabindex="-1"
                  >
                    Lupa Password?
                  </router-link>
                </div>
                <v-text-field
                  filled
                  v-model="password"
                  placeholder="Masukkan password"
                  :type="show_password"
                  outlined
                  hide-details="auto"
                  :rules="rules('Password', 'required')"
                  append-icon="$eye"
                  @click:append="show_password = show_password == 'text' ? 'password' : 'text'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-spacer class="py-2"></v-spacer>
            <v-row>
              <v-col>
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  block
                >
                  Masuk
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import validationMixin from "../_mixins/validation";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      valid: true,
      errors: null,
      email: "",
      password: "",
      submiting: false,
      show_password: 'password'
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  methods: {
    submit() {
      this.errors = null;

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          let email = this.email;
          let password = this.password;
  
          this.$store
            .dispatch("login", { email, password })
            .then(() => {
              this.submiting = false;
  
              if (sessionStorage.getItem("ytc_redirect") != null) {
                this.$router.push(sessionStorage.getItem("ytc_redirect"));
                sessionStorage.removeItem("ytc_redirect");
              } else {
                this.$router.push("/dashboard");
              }
            })
            .catch((error) => {
              this.submiting = false;
              this.errors = error.response.data;
            });
        } 
      }, 50);
    },
  },
};
</script>

<style>
</style>